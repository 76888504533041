@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&display=swap');

:root {
    --navy: #17252A;
    --medium: #296969;
    --emerald: #3AAFA9;
    --light: #8CC6C3;
    --white: #DDDDDD;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

html, body {
    background: var(--white);
    color: var(--navy);
    font-family: Cairo, sans-serif;
}

a {
    color: var(--navy);
}

svg {
    fill: var(--navy);
}

nav {
    width: 100%;
    padding: 25px;
    position: fixed;
    top: 0;
    left: 0;
}

nav.scrolled {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(15px);
}

nav ul {
    display: flex;
    justify-content: center;
    gap: 50px;
}

nav ul li {
    font-size: 1.25rem;
}

.isHovered {
    font-weight: bold;
    color: var(--medium);
}

#sns {
    display: flex;
    gap: 25px;
}

#sns svg{
    height: 25px;
}

#sns svg:hover {
    height: 27px;
    fill: var(--medium);
}

#home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-size: 1.5rem;
}

#home h1{
    font-size: 4rem;
    line-height: 75px;
    color: var(--emerald)
}

.btn {
    font-size: 1.25rem;
    background: var(--light);
    padding: 5px 10px;
    border-radius: 15px;
}

section {
    margin: 100px auto;
    max-width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    font-size: 2rem;
    margin: 50px;
}

#about .main {
    display: flex;
    justify-content: center;
    /*align-items: center;*/
}

#about .main img {
    width: 100%;
    margin-right: 100px;
    background: var(--light);
}

#about .main div {
    max-width: 60%;
}

#about .main div a {
    color: var(--medium)
}

#about .main div a:hover {
    font-weight: bold;
}

#education .main {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.school {
    background: #d9d9d9;
    border: 1px solid var(--navy);
    padding: 25px;
}

.school .name {
    color: var(--medium);
}

.school .degree {
    font-weight: 300;
}

.school span {
    font-weight: bold;
}

#projects .main {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
}

.project {
    background: #d9d9d9;
    border: 1px solid var(--navy);
    padding: 15px;
    display: flex;
    flex-direction: column;
}

.project img {
    height: 200px;
    background: var(--light);
    width: 100%;
}

.project .tech {
    display: flex;
    gap: 10px;
    font-size: 0.75rem;
}

.project .tech p {
    margin-top: 10px;
    padding: 0 5px;
    border: 1px solid var(--navy);
    border-radius: 5px;
    background: var(--light);
    font-weight: bold;
}

#contact .main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#contact-p {
    width: 75%;
}

.form-contact {
    display: flex;
    align-items: center;
    gap: 50px;
    margin: 25px;
}

#contact form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

#contact form input, #contact form textarea {
    padding: 5px;
    font-family: inherit;
    font-size: 1rem;
    background: #d9d9d9;
    border: none;
    border-bottom: 1px solid var(--navy);
    flex-grow: 1;
}

#contact input::placeholder, #contact textarea::placeholder {
    font-family: inherit;
    color: var(--medium);
}

#contact input[type="submit"] {
    background: var(--light);
    border: none;
    border-radius: 15px;
    color: var(--navy);
    font-weight: bold;
}

#contact input[type="submit"]:hover {
    transform: scale(1.05);
}


textarea {
    resize: none;
}

.row {
    display: flex;
    gap: 15px;
}

#contact svg {
    height: 25px;
}

#contact-other {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.contact-other {
    display: flex;
    align-items: center;
    gap: 15px;
    text-align: center;
}

.contact-other span, .contact-other a {
    color: var(--light);
}

footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--light);
    padding: 25px;
    box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25);
}

footer svg {
    fill: var(--light);
}